export const WDS_Z_INDEX_DEEPEST = -9999;
export const WDS_Z_INDEX_BASE = 1;
export const WDS_Z_INDEX_OVER_CONTENT = 3;
export const WDS_Z_INDEX_DROPDOWN_FILTERS = 2;
export const WDS_Z_INDEX_DROPDOWN = 10;
export const WDS_Z_INDEX_TOOLTIP = 20;
export const WDS_Z_INDEX_THROBBER = 99;
export const WDS_Z_INDEX_OVERLAY = 100;
export const WDS_Z_INDEX_MODAL = 200;
export const WDS_Z_INDEX_TOAST = 300;
export const WDS_Z_INDEX_HIGHEST = 9999;
